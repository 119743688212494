import * as React from 'react';

import {
  Layout,
  SEO,
  Hero,
  Banner,
  Contact,
  HeadingWithCopy,
} from '../components';
import { useGraphQL } from '../hooks';

function AircraftPage() {
  const { aircraftBannerImage } = useGraphQL();

  return (
    <Layout>
      <SEO title="Aircraft" />
      <AircraftHero />
      <article className="relative w-full px-4 py-12 mx-auto bg-white lg:py-24 max-w-screen-2xl sm:px-6 lg:px-8">
        <h2 className="text-center heading-2">Our aircraft</h2>
        <div className="w-full max-w-2xl mx-auto mt-8 prose text-center">
          <p>
            R&M Aviation utilises the best equipment to provide the excellent
            aviation standards on which our customers rely.
          </p>
          <p>
            We operate a variety of aircraft and specialise in the self-fill
            amphibious firefighting plane.
          </p>
        </div>
      </article>
      <Banner image={aircraftBannerImage.childImageSharp.fluid} />
      <TheFireboss />
      <AircraftTractor />
      <SuperScooper />
      <Contact bgClassName="bg-grey" />
    </Layout>
  );
}

function AircraftHero() {
  const { airTractorAT802FImage } = useGraphQL();

  return (
    <Hero image={airTractorAT802FImage.childImageSharp.fluid}>
      <h1 className="heading-1">Aircraft</h1>
    </Hero>
  );
}

function TheFireboss() {
  const { theFirebossImage } = useGraphQL();

  return (
    <HeadingWithCopy
      heading="The FireBoss"
      image={theFirebossImage.childImageSharp.fluid}
      isAircraft
    >
      <p>
        R&amp;M Aviation specialise in Fireboss operations. We were one of the
        first companies in the world to operate the Fireboss, introducing it to
        the Southern Hemisphere in 2008.
      </p>
      <p>
        Our successful integration of the Fireboss into Australian operations
        has seen us accumulate thousands of operational hours. Our CEO is one of
        the most accomplished Fireboss pilots in the world.
      </p>
      <p>
        R&amp;M Aviation designed its own unique GEL injection system for the
        Fireboss, whereby injection occurs after scooping. This system allows
        the Fireboss to mix GEL onboard.
      </p>
    </HeadingWithCopy>
  );
}

function AircraftTractor() {
  const { airTractorImage } = useGraphQL();

  return (
    <HeadingWithCopy
      heading="Air Tractor AT-802F"
      image={airTractorImage.childImageSharp.fluid}
      isAircraft
      onGrey
    >
      <p>
        Over our 50 years, we have operated numerous Single Engine Air Tankers
        (SEATs), including M-18 Dromaders and Ayres Thrushes. We continue to
        operate the Air Tractor AT-802F aircraft.
      </p>
      <p>
        The AT-802 aircraft we operate have increased engine power with a 1600
        HP turbine engine, providing confidence to perform in difficult
        firefighting conditions.
      </p>
    </HeadingWithCopy>
  );
}

function SuperScooper() {
  const { superScooperImage } = useGraphQL();

  return (
    <HeadingWithCopy
      heading="The Next Generation - The Super Scooper "
      image={superScooperImage.childImageSharp.fluid}
      isAircraft
    >
      <p>
        Our history and experience in Aerial Firefighting has led us to closely
        follow the development of the Viking Air CL-415EAF program and the
        expected production of the next generation CL-515.
      </p>
      <p>
        The features of the aircraft we find particularly appealing are its
        productivity, endurance levels, improved safety ability and versatility.
      </p>
      <p>
        It is the only purpose-built firefighting aircraft in the world, capable
        of scooping up to 6000 litres of water (and injecting gel or foam
        mid-flight) to deliver a continuous rapid attack to a fire for up to
        eight hours (its full fuel cycle).
      </p>
      <p>
        For more information,{` `}
        <a
          href="https://aerialfirefighter.vikingair.com/sites/aerialfirefighter.vikingair.com/files/docs/canadair_infosheet_june2020_final_v2.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          click here.
        </a>
      </p>
    </HeadingWithCopy>
  );
}

export default AircraftPage;
